
export default {
    settings: (vendor_id) => window.axios.get('settings?' + 'vendor_id='+vendor_id+''),
    DeliveryCharge: (data) => window.axios.post('getDeliveryCharge',data),
    PlaceOrder: (data) => window.axios.post('webPlaceOrder',data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }),
    viewOrderDetails: (data) => window.axios.post('view_order_details',data),
    onGoingOrder: (data) => window.axios.post('web_on_going_orders',data),

}