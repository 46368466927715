<template>
  <div class="home">
    <ViewOrderDetails/>
  </div>
</template>

<script>
// @ is an alias to /src
import ViewOrderDetails from '@/components/ViewOrderDetails.vue';

export default {
  name: 'order_details',
  components: {
    ViewOrderDetails,
  },
};
</script>
