<template>
 <div class="order_page">
     <div class="wrapper">
      <div class="image-holder">
        <img src="../assets/images/hungryy_logo_round.png" alt="Hungryy" />
      </div>

     <div id="wizard">
       <div class="order_page">
        <div class="login-main">
          <section class="order-details-start">
            <div class="review-container review-main text-capitalize position-relative">
              <div class="row">
                <div class="col-md-12 p-0" v-for="(orders,index) in ordersDetail" :key="index">
                  <div class="resturant-details plr15 order-id">
                    <h4 class="text-left">Order #{{orders.order_id}}({{orders.short_code}})</h4>
                  </div>
                  <div class="orders-inner-main plr15 mt-2">
                    <div class="orders-lst">
                      <div class="order-item d-flex align-items-start pb-4">
                        <img :src="orders.payment_photo" />
                        <div class="order-data ml-3 d-flex align-items-start justify-content-between w-100">
                          <div class="orderid-main">
                            <h6 class="mb-0">{{orders.web_order_restaurant_name}}</h6>
                            <p class="mt-1">{{orders.cuisine_name.join(', ')}}</p>
                            <a class="mt-2 d-inline-block" :href="'tel:64846799'">
                              <i class="fas fa-phone-alt"></i> Call Support
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="inner-data">
                        <h6 class="mb-1 mt-3">Your Order</h6>
                        <div class="order-history-main" v-for="(restaurant,index) in orders.restaurant_item_details" :key="index">
                          <div class="order-histry d-flex justify-content-between align-items-start">
                            <div class="histry-name item-heading delivery-link">
                              <div class="veg" v-if="restaurant.item_type == 0">
                                <span></span>
                              </div>
                              <div class="non-veg" v-else-if="restaurant.item_type == 1">
                                <span></span>
                              </div>
                              <div v-else-if="restaurant.item_type == 2">
                                <span></span>
                              </div>
                              <p class="mb-0">{{restaurant.restaurant_menu_item_name}}</p>
                              <span>{{restaurant.quantity}} item</span>
                            </div>
                            <div class="histry-price">
                              <span></span> <span>{{parseFloat(restaurant.amount).toFixed(2)}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="billing-data border-0 mt-2">
                          <h6 class="mb-2">Billing Details</h6>
                          <ul>
                             <li class="d-flex justify-content-between">
                              Sender Name
                              <div>
                                <span ></span> <span>{{orders.restaurant_name}}</span>
                              </div>
                            </li>
                             <li class="d-flex justify-content-between">
                              Sender Number
                              <div>
                                <span ></span> <span>{{orders.restaurant_number}}</span>
                              </div>
                            </li>
                            <li class="d-flex justify-content-between">
                              Sender Address
                              <div>
                                <span ></span> <span>{{orders.restaurant_address}}</span>
                              </div>
                            </li>

                            <li class="d-flex justify-content-between">
                              SubTotal
                              <div>
                                <span ></span> <span>{{parseFloat(orders.sub_total).toFixed(2)}}</span>
                              </div>
                            </li>
                            <li class="d-flex justify-content-between">
                              Delivery Charges
                              <div>
                                <span ></span> <span>{{parseFloat(orders.delivery_fee).toFixed(2)}}</span>
                              </div>
                            </li>
                            <li class="d-flex justify-content-between">
                              GST
                              <div>
                                <span ></span> <span>{{parseFloat(orders.service_fee).toFixed(2)}}</span>
                              </div>
                            </li>
                            <li class="d-flex justify-content-between discount-price" v-if="orders.discount_price">
                              Discount
                              <div>
                                <span ></span> <span>{{parseFloat(orders.discount_price).toFixed(2)}}</span>
                              </div>
                            </li>
                            <li class="d-flex justify-content-between bold">
                              Total Paid
                              <div>
                                <span ></span> <span>{{parseFloat(orders.total_amount).toFixed(2)}}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="billing-data border-0 mt-2">
                          <h6 class="mb-2">Order Details</h6>
                          <ul>
                            <li class="d-flex justify-content-between">
                              Order Number
                              <span>#{{orders.order_id}}</span>
                            </li>
                             <li class="d-flex justify-content-between">
                              Order Status

                              <span v-if="orders.order_status == 'Placed'">Order pending confirmation</span>
                              <span v-else-if="orders.order_status == 'ReadyToServe' && (orders.drivername == '')">Order is confirmed</span>
                               <span v-else-if="orders.drivername != '' && (orders.order_status == 'ReadyToServe' || orders.order_status == 'OnPickUp') ">Delivery partner {{orders.drivername}} in transit to pick up item</span>
                              <span v-else-if="orders.order_status == 'OnTheWay'">Delivery partner otw to delivery address</span>
                              <span v-else-if="orders.order_status == 'Delivered'">Order has been delivered </span>
                            </li>
                            <li class="d-flex justify-content-between">
                              Placed Date
                              <span>{{moment(orders.order_date).format('DD/MM/Y hh:mm A')}}</span>
                            </li>
                            <li class="d-flex justify-content-between">
                              Order Type
                              <span>{{orders.delivery_pickup_types}}</span>
                            </li>
                            <li class="d-flex justify-content-between">
                              Payment Mode
                              <span>{{orders.payment_method}}</span>
                            </li>
                            <li class="d-flex justify-content-between delivery-locality">
                              Delivery Address
                              <span>{{orders.delivery_address}}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="past" v-if="orders.cancelled_desc" class="tab-pane tab-box cncel-ordr">
                    <h6>Cancelled Order</h6>
                    <p>{{orders.cancelled_desc}}</p></div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
     </div>
 </div>
 </div>
</template>
<script>
import moment from "moment";
import Setting from "../apis/setting";

export default {
 // props:['ordersDetail'],
   data(){
        return{
            moment: moment,
            ordersDetail: [],          
        }
    },
    mounted(){
      console.log("View ORder Details");
      this.ordersDetails();
    },
    methods:{
      ordersDetails: function(){
         Setting.viewOrderDetails({
          vendor_id: 40818,
          order_id: this.$route.params.order_id,
        }).then((response) => {
          console.log("Ongoinf View"+response);
           if(response.data.code == 200){

             this.orderListing = false;
            this.isOrderDetails = true;
            this.ordersDetail = response.data.Result;
           }else{
              this.$toasted.error(response.data.msg, { duration: 4000 });
          }

        });
      }
    }
}
</script>
